import { tw } from '../utils/classes'

import type { ColourType, ColourUsage, ExtendedColourType } from '../types'

export const gradientBgBase = tw`bg-gradient-to-tl`
export const gradientBgViaPrimary = tw`${gradientBgBase} from-pitaya-pink via-sunset-orange to-lime-rasp`
export const gradientBgDark = tw`${gradientBgBase} from-pitaya-pink-900 via-pitaya-pink-800 to-pitaya-pink-700`
export const gradientBgViaSubPrimary = tw`${gradientBgBase} from-pitaya-pink via-sunset-orange to-lime-rasp`

export const colorsBgLight: Record<ColourType, string> = {
  white: tw`bg-pitaya-core text-pitaya-pip`,
  light: tw`bg-pitaya-core text-pitaya-pip dark:bg-pitaya-pip dark:text-pitaya-core`,
  contrast: tw`bg-pitaya-pip text-pitaya-core dark:bg-pitaya-core dark:text-pitaya-pip`,
  success: tw`bg-lime-rasp border-jade-glass text-pitaya-pip`,
  danger: tw`bg-pitaya-pink border-jade-glass text-pitaya-core`,
  warning: tw`bg-sunset-orange border-jade-glass text-pitaya-pip`,
  info: tw`bg-surf-blue border-jade-glass text-pitaya-pip`,
}

export const colorsText: Record<ColourType, string> = {
  white: tw`text-pitaya-pip dark:text-pitaya-core`,
  light: tw`text-nickel dark:text-nickel`,
  contrast: tw`dark:text-pitaya-core`,
  success: tw`text-hue-sub-secondary-alt`,
  danger: tw`text-pitaya-pink`,
  warning: tw`text-sunset-orange`,
  info: tw`text-surf-blue`,
}

export const colorsOutline: Record<ColourType, string[]> = {
  white: [colorsText.white, tw`border-jade-glass`],
  light: [colorsText.light, tw`border-jade-glass`],
  contrast: [colorsText.contrast, tw`border-pitaya-pip dark:border-pitaya-core`],
  success: [colorsText.success, tw`border-jade-glass`],
  danger: [colorsText.danger, tw`border-jade-glass`],
  warning: [colorsText.warning, tw`border-jade-glass`],
  info: [colorsText.info, tw`border-jade-glass`],
}

export const getButtonColor = (color: ExtendedColourType, isOutlined: boolean, hasHover: boolean, isActive = false) => {
  const colors: Record<ColourUsage, Partial<Record<ExtendedColourType, string>>> = {
    ring: {
      white: tw`ring-jade-glass dark:ring-pitaya-core-500`,
      whiteDark: tw`ring-jade-glass dark:ring-pitaya-core-500`,
      lightDark: tw`ring-jade-glass dark:ring-pitaya-core-500`,
      contrast: tw`ring-jade-glass dark:ring-pitaya-core-400`,
      success: tw`ring-jade-glass dark:ring-bergamot-700`,
      danger: tw`ring-jade-glass dark:ring-pitaya-pink-700`,
      warning: tw`ring-jade-glass dark:ring-sunset-orange-700`,
      info: tw`ring-jade-glass dark:ring-surf-blue-700`,
    },
    active: {
      white: tw`bg-pitaya-core-100 dark:bg-pitaya-core-800`,
      whiteDark: tw`bg-pitaya-core-100 dark:bg-pitaya-pip-800`,
      lightDark: tw`bg-pitaya-core-200 dark:bg-pitaya-pip-700`,
      contrast: tw`bg-pitaya-core-700 dark:bg-pitaya-pip-100`,
      success: tw`bg-lime-rasp dark:bg-lime-rasp-600`,
      danger: tw`bg-pitaya-pink dark:bg-pitaya-pink-600`,
      warning: tw`bg-sunset-orange dark:bg-sunset-orange-600`,
      info: tw`bg-surf-blue dark:bg-surf-blue-600`,
    },
    bg: {
      white: tw`bg-pitaya-core text-pitaya-pip dark:bg-pitaya-core-900 dark:text-pitaya-core`,
      whiteDark: tw`bg-pitaya-core text-pitaya-pip dark:bg-pitaya-pip-900 dark:text-pitaya-core`,
      lightDark: tw`bg-pitaya-core-100 text-pitaya-pip dark:bg-pitaya-pip-800 dark:text-pitaya-core`,
      contrast: tw`bg-pitaya-core-800 text-pitaya-core dark:bg-pitaya-core dark:text-pitaya-pip`,
      success: tw`bg-lime-rasp dark:bg-lime-rasp-500 text-pitaya-core dark:text-pitaya-pip`,
      danger: tw`bg-pitaya-pink dark:bg-pitaya-pink-500 text-pitaya-core dark:text-pitaya-pip`,
      warning: tw`bg-sunset-orange dark:bg-sunset-orange-500 text-pitaya-core dark:text-pitaya-pip`,
      info: tw`bg-surf-blue dark:bg-surf-blue-500 text-pitaya-pip`,
    },
    bgHover: {
      white: tw`hover:bg-pitaya-core-200 dark:hover:bg-pitaya-core-700`,
      whiteDark: tw`hover:bg-pitaya-core-200 hover:dark:bg-pitaya-pip-700`,
      lightDark: tw`hover:bg-pitaya-core-300 hover:dark:bg-pitaya-pip-600`,
      contrast: tw`hover:bg-pitaya-core-600 hover:dark:bg-pitaya-pip-200`,
      success: tw`hover:bg-lime-rasp hover:border-lime-rasp dark:hover:bg-lime-rasp-700 dark:hover:border-lime-rasp-700`,
      danger: tw`hover:bg-pitaya-pink hover:border-pitaya-pink dark:hover:bg-pitaya-pink-700 dark:hover:border-pitaya-pink-700`,
      warning: tw`hover:bg-sunset-orange hover:border-sunset-orange dark:hover:bg-sunset-orange-700 dark:hover:border-sunset-orange-700`,
      info: tw`hover:bg-surf-blue hover:border-surf-blue dark:hover:bg-surf-blue-700 dark:hover:border-surf-blue-700`,
    },
    borders: {
      white: tw`border-jade-glass dark:border-jade-glass-800`,
      whiteDark: tw`border-jade-glass dark:border-jade-glass-800`,
      lightDark: tw`border-jade-glass dark:border-jade-glass-800`,
      contrast: tw`border-jade-glass dark:border-jade-glass-800`,
      success: tw`border-jade-glass dark:border-jade-glass-800`,
      danger: tw`border-jade-glass dark:border-jade-glass-800`,
      warning: tw`border-jade-glass dark:border-jade-glass-800`,
      info: tw`border-jade-glass dark:border-jade-glass-800`,
    },
    text: {
      contrast: tw`text-pitaya-core dark:text-pitaya-pip-100`,
      success: tw`text-lime-rasp dark:text-lime-rasp-500`,
      danger: tw`text-pitaya-pink dark:text-pitaya-pink-500`,
      warning: tw`text-sunset-orange dark:text-sunset-orange-500`,
      info: tw`text-surf-blue dark:text-surf-blue-500`,
    },
    outlineHover: {
      contrast: tw`hover:bg-pitaya-core-600 hover:text-pitaya-core-200 dark:hover:bg-pitaya-pip-200 dark:hover:text-pitaya-pip`,
      success: tw`hover:bg-lime-rasp hover:text-pitaya-core dark:hover:text-pitaya-core dark:hover:border-lime-rasp-700`,
      danger: tw`hover:bg-pitaya-pink hover:text-pitaya-core dark:hover:text-pitaya-core dark:hover:border-pitaya-pink-700`,
      warning: tw`hover:bg-sunset-orange hover:text-pitaya-core dark:hover:text-pitaya-core dark:hover:border-sunset-orange-700`,
      info: tw`hover:bg-surf-blue hover:text-pitaya-core dark:hover:text-pitaya-core dark:hover:border-surf-blue-700`,
    },
  }

  if (!colors.bg[color]) {
    return color
  }

  const isOutlinedProcessed = isOutlined && ![tw`white`, tw`whiteDark`, tw`lightDark`].includes(color)

  const base = [colors.borders[color], colors.ring[color]]

  if (isActive) {
    base.push(colors.active[color])
  } else {
    base.push(isOutlinedProcessed ? colors.text[color] : colors.bg[color])
  }

  if (hasHover) {
    base.push(isOutlinedProcessed ? colors.outlineHover[color] : colors.bgHover[color])
  }

  return base
}
