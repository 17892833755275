import { defineStore } from 'pinia'

import { darkModeKey, styleKey } from '../config/config'
import * as styleConfig from '../config/styles'

import type { StyleConfig, StyleConfigKey } from '../types'
import type { Ref } from 'vue'

type StyleKey = `${StyleConfigKey}Style`
export const useStyleStore = defineStore('style', () => {
  /* Styles */
  const styles: Record<StyleKey, Ref<string>> = {
    asideStyle: ref<string>(''),
    asideScrollbarsStyle: ref<string>(''),
    asideBrandStyle: ref<string>(''),
    asideMenuItemStyle: ref<string>(''),
    asideMenuItemActiveStyle: ref<string>(''),
    asideMenuDropdownStyle: ref<string>(''),
    navBarItemLabelStyle: ref<string>(''),
    navBarItemLabelHoverStyle: ref<string>(''),
    navBarItemLabelActiveColorStyle: ref<string>(''),
    overlayStyle: ref<string>(''),
  }

  /* Dark mode */
  const darkMode = ref(false)

  function setStyle(payload: keyof typeof styleConfig) {
    if (!styleConfig[payload]) {
      return
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(styleKey, payload)
    }

    const style = styleConfig[payload] as StyleConfig

    for (const key in style) {
      const styleKey = `${key}Style` as StyleKey
      if (styleKey in styles) {
        styles[styleKey].value = style[key as StyleConfigKey]
      }
    }
  }

  function setDarkMode(payload = null) {
    darkMode.value = payload !== null ? payload : !darkMode.value

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem(darkModeKey, darkMode.value ? '1' : '0')
    }

    if (typeof document !== 'undefined') {
      document.body.classList[darkMode.value ? 'add' : 'remove']('dark-scrollbars')

      document.documentElement.classList[darkMode.value ? 'add' : 'remove']('dark-scrollbars-compat')
    }
  }

  return {
    ...styles,
    darkMode,
    setStyle,
    setDarkMode,
  }
})
