<script setup>
import '../assets/css/main.css'
import Icon128 from '../assets/images/brand/icon/icon_128x128.png'
import Icon16 from '../assets/images/brand/icon/icon_16x16.png'
import Icon192 from '../assets/images/brand/icon/icon_192x192.png'
import Icon256 from '../assets/images/brand/icon/icon_256x256.png'
import Icon32 from '../assets/images/brand/icon/icon_32x32.png'
import Icon48 from '../assets/images/brand/icon/icon_48x48.png'
import Icon64 from '../assets/images/brand/icon/icon_64x64.png'
import Icon96 from '../assets/images/brand/icon/icon_96x96.png'

import { darkModeKey, styleKey } from './config/config'

useHead({
  titleTemplate: (titleChunk) => {
    const titleBase = 'For My Event'

    return titleChunk ? `${titleChunk} - ${titleBase}` : titleBase
  },
  link: [
    { rel: 'icon', href: Icon16, type: 'image/png', sizes: '16x16' },
    { rel: 'icon', href: Icon32, type: 'image/png', sizes: '32x32' },
    { rel: 'icon', href: Icon48, type: 'image/png', sizes: '48x48' },
    { rel: 'icon', href: Icon64, type: 'image/png', sizes: '64x64' },
    { rel: 'icon', href: Icon96, type: 'image/png', sizes: '96x96' },
    { rel: 'icon', href: Icon128, type: 'image/png', sizes: '128x128' },
    { rel: 'icon', href: Icon192, type: 'image/png', sizes: '192x192' },
    { rel: 'icon', href: Icon256, type: 'image/png', sizes: '256x256' },
  ],
})

const styleStore = useStyleStore()

const currentStyle = typeof localStorage !== 'undefined' && localStorage[styleKey] ? localStorage[styleKey] : 'basic'

styleStore.setStyle(currentStyle)
onMounted(() => {
  const currentStoredDarkMode = typeof localStorage !== 'undefined' && localStorage[darkModeKey] === '1'

  if (
    (!currentStoredDarkMode &&
      typeof window !== 'undefined' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches) ||
    currentStoredDarkMode
  ) {
    styleStore.setDarkMode(true)
  }
})
</script>

<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<style scoped>
.logos {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
</style>
