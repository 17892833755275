import { tw } from '../utils/classes'

import type { StyleConfig } from '../types'

export const basic: StyleConfig = {
  aside: tw`bg-pitaya-pip text-pitaya-core font-pitaya font-medium text-base leading-6`,
  asideScrollbars: tw`aside-scrollbars-pumice`,
  asideBrand: tw`bg-pitaya-pip-700 text-pitaya-core font-pitaya font-bold text-2xl leading-8`,
  asideMenuItem: tw`text-pumice-300 hover:text-pitaya-core font-pitaya font-medium text-sm leading-5`,
  asideMenuItemActive: tw`font-bold text-pitaya-core font-pitaya text-sm leading-5`,
  asideMenuDropdown: tw`bg-nickel/50`,
  navBarItemLabel: tw`text-pitaya-pip font-pitaya font-medium text-base leading-6`,
  navBarItemLabelHover: tw`hover:text-pitaya-pink-500 font-pitaya font-medium text-base leading-6`,
  navBarItemLabelActiveColor: tw`text-pitaya-pink-600 font-pitaya font-medium text-base leading-6`,
  overlay: tw`from-nickel via-pitaya-pip to-nickel`,
}

export const white: StyleConfig = {
  aside: tw`bg-pitaya-core text-pitaya-pip font-pitaya font-medium text-base leading-6`,
  asideScrollbars: tw`aside-scrollbars-light`,
  asideBrand: tw`font-pitaya font-bold text-2xl leading-8`,
  asideMenuItem: tw`text-pitaya-pink-600 hover:text-pitaya-pip dark:text-pitaya-core font-pitaya font-medium text-sm leading-5`,
  asideMenuItemActive: tw`font-bold text-pitaya-pip dark:text-pitaya-core font-pitaya text-sm leading-5`,
  asideMenuDropdown: tw`bg-pumice/75`,
  navBarItemLabel: tw`text-pitaya-pink-600 font-pitaya font-medium text-base leading-6`,
  navBarItemLabelHover: tw`hover:text-pitaya-pip font-pitaya font-medium text-base leading-6`,
  navBarItemLabelActiveColor: tw`text-pitaya-pip font-pitaya font-medium text-base leading-6`,
  overlay: tw`from-pitaya-core via-pumice to-pitaya-core`,
}
