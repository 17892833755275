import revive_payload_client_YTGlmQWWT5 from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import fontawesome_cn2c4tOOHz from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/plugins/fontawesome.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_AOuQ1DYzjk,
  fontawesome_cn2c4tOOHz
]