<script lang="ts" setup>
const error = useError()
</script>

<template>
  <div>
    <NuxtLayout>
      <SectionFullScreen v-slot="{ cardClass }" bg="pinkRed">
        <CardBox :class="cardClass">
          <div class="space-y-3">
            <h1 class="text-2xl">Error {{ error.statusCode }}</h1>

            <h2>{{ error.statusMessage }}</h2>
          </div>

          <template #footer>
            <BaseButtons>
              <BaseButton label="Done" to="/" color="danger" />
            </BaseButtons>
          </template>
        </CardBox>
      </SectionFullScreen>
    </NuxtLayout>
  </div>
</template>
