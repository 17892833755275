<script setup lang="ts">
interface BaseButtonsProps {
  noWrap?: boolean
  type?: 'justify-start' | 'justify-end' | 'justify-center' | 'justify-between' | 'justify-around' | 'justify-evenly'
  classAddon?: string
  mb?: string
}

const props = withDefaults(defineProps<BaseButtonsProps>(), {
  noWrap: false,
  type: 'justify-start',
  classAddon: 'mr-3 last:mr-0 mb-3',
  mb: '-mb-3',
})

const slots = useSlots()
const hasSlot = computed(() => slots.default())

const parentClass = computed(() => [
  'flex',
  'items-center',
  props.type,
  props.noWrap ? 'flex-nowrap' : 'flex-wrap',
  props.mb,
])
</script>

<template>
  <div :class="parentClass">
    <template v-if="hasSlot">
      <slot />
    </template>
  </div>
</template>
