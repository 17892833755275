<script setup lang="ts">
interface Props {
  rounded?: string
  flex?: string
  hasComponentLayout?: boolean
  hasTable?: boolean
  isForm?: boolean
  isHoverable?: boolean
  isModal?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  rounded: 'rounded-2xl',
  flex: 'flex-col',
  hasComponentLayout: false,
  hasTable: false,
  isForm: false,
  isHoverable: false,
  isModal: false,
})

const emit = defineEmits<{
  (_e: 'submit', _event: Event): void
}>()

const slots = useSlots()

const hasFooterSlot = computed(() => slots.footer && !!slots.footer())

const componentClass = computed(() => {
  const base = [props.rounded, props.flex, props.isModal ? 'dark:bg-nickel-900' : 'dark:bg-nickel-900/70']

  if (props.isHoverable) {
    base.push('hover:shadow-lg transition-shadow duration-500')
  }

  return base
})

const submit = (event: Event) => {
  emit('submit', event)
}
</script>

<template>
  <component
    :is="isForm ? 'form' : 'div'"
    :class="componentClass"
    class="flex bg-pitaya-core dark:text-white"
    @submit="submit"
  >
    <slot v-if="hasComponentLayout" />
    <template v-else>
      <CardBoxComponentBody :no-padding="hasTable">
        <slot />
      </CardBoxComponentBody>
      <CardBoxComponentFooter v-if="hasFooterSlot" class="not-prose">
        <slot name="footer" />
      </CardBoxComponentFooter>
    </template>
  </component>
</template>
