<script setup lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import type { Sizes, WithIcon, AllSizes } from '../types'

interface Props extends WithIcon {
  w?: string | null
  h?: string | null
  size?: Sizes | null
}

const sizeToWH: Record<AllSizes, { w: string; h: string }> = {
  '2xs': { w: 'w-3', h: 'h-3' },
  'xs': { w: 'w-4', h: 'h-4' },
  'sm': { w: 'w-5', h: 'h-5' },
  'md': { w: 'w-6', h: 'h-6' },
  'lg': { w: 'w-8', h: 'h-8' },
  'xl': { w: 'w-10', h: 'h-10' },
  '2xl': { w: 'w-12', h: 'h-12' },
}

const props = withDefaults(defineProps<Props>(), {
  w: null,
  h: null,
  size: null,
})

const w = computed(() => props.w || sizeToWH[props.size || 'md'].w)
const h = computed(() => props.h || sizeToWH[props.size || 'md'].h)

const spanClass = computed(() => `inline-flex justify-center items-center ${w.value} ${h.value}`)

const iconType = computed(() => {
  if (props.icon?.endsWith('-solid')) return 'fas'
  if (props.icon?.endsWith('-brand')) return 'fab'
  return 'far'
})

const iconName = computed(() => {
  if (iconType.value === 'fas') return props.icon?.replace('-solid', '')
  if (iconType.value === 'fab') return props.icon?.replace('-brand', '')
  return props.icon
})
</script>

<template>
  <span :class="spanClass">
    <FontAwesomeIcon v-if="iconName" :icon="[iconType, iconName]" :size="size || undefined" />
    <slot />
  </span>
</template>
