<script setup lang="ts">
import { getButtonColor } from '../config/colors'

import Link from './Atom/Link.vue'

import type { ExtendedColourType, IconLabel, Sizes } from '../types'

interface Props {
  label?: string | number | null
  icon?: IconLabel | null
  iconSize?: Sizes | null
  href?: string | null
  target?: string | null
  to?: string | object | null
  type?: 'button' | 'submit' | 'reset' | null
  color?: ExtendedColourType
  as?: string | null
  small?: boolean
  outline?: boolean
  active?: boolean
  disabled?: boolean
  roundedFull?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: null,
  icon: null,
  target: null,
  color: 'white',
  as: null,
  small: false,
  outline: false,
  active: false,
  disabled: false,
  roundedFull: false,
  type: null,
  href: null,
  to: null,
  iconSize: null,
})

const labelClass = computed(() => (props.small && props.icon ? 'px-1' : 'px-2'))

const componentClass = computed(() => {
  const base = [
    'inline-flex',
    'justify-center',
    'items-center',
    'whitespace-nowrap',
    'focus:outline-none',
    'transition-colors',
    'focus:ring',
    'duration-150',
    'border',
    props.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    props.roundedFull ? 'rounded-full' : 'rounded',
    getButtonColor(props.color, props.outline, !props.disabled, props.active),
  ]

  if (!props.label && props.icon) {
    base.push('p-1')
  } else if (props.small) {
    base.push('text-sm', props.roundedFull ? 'px-3 py-1' : 'p-1')
  } else {
    base.push('py-2', props.roundedFull ? 'px-6' : 'px-3')
  }

  if (props.disabled) {
    base.push(props.outline ? 'opacity-50' : 'opacity-70')
  }

  return base
})
</script>

<template>
  <Link
    :is="props.as"
    :class="componentClass"
    :href="props.href"
    :type="props.type || undefined"
    :to="props.to || null"
    :target="props.target || null"
    :disabled="props.disabled"
  >
    <BaseIcon v-if="props.icon" :icon="props.icon" :size="props.iconSize" />
    <span v-if="props.label" :class="labelClass">{{ props.label }}</span>
  </Link>
</template>
