<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'

interface LinkProps {
  href?: string | null
  to?: RouteLocationRaw | null
  type?: 'button' | 'submit' | 'reset'
  as?: string | null
  disabled?: boolean
}

const props = withDefaults(defineProps<LinkProps>(), {
  href: null,
  to: null,
  type: undefined,
  as: null,
  disabled: false,
})

const emit = defineEmits<{
  (_e: 'click', _event: Event): void
}>()

const isButton = computed(() => !props.as && !props.to && !props.href)
const isNuxtLink = (as: string | null, to: RouteLocationRaw | null): to is RouteLocationRaw => !as && to !== null
const isAnchor = (as: string | null, href: string | null): href is string => !as && href !== null

const cursorClass = computed(() => (props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'))
defineSlots<{
  default: {
    isExactActive: boolean
  }
}>()

const handleClick = (event: Event) => {
  emit('click', event)
}
</script>

<template>
  <button
    v-if="isButton"
    :type="type ?? 'button'"
    :class="[cursorClass]"
    :disabled="disabled"
    v-bind="$attrs"
    @click="handleClick"
  >
    <slot :is-exact-active="false" />
  </button>
  <NuxtLink
    v-else-if="isNuxtLink(as, to)"
    v-slot="{ isExactActive }"
    :to="to"
    :class="[cursorClass, { disabled: disabled }]"
    v-bind="$attrs"
    @click="handleClick"
  >
    <slot :is-exact-active="isExactActive" />
  </NuxtLink>
  <a
    v-else-if="isAnchor(as, href)"
    :href="href"
    :class="[cursorClass, { disabled: disabled }]"
    v-bind="$attrs"
    @click="handleClick"
  >
    <slot :is-exact-active="false" />
  </a>
  <component :is="as" v-else :class="[cursorClass, { disabled: disabled }]" v-bind="$attrs" @click="handleClick">
    <slot :is-exact-active="false" />
  </component>
</template>
