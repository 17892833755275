import _ctl from '@netlify/classnames-template-literals'

export const decorateClasses = (prefix: string, suffix: string, classes: string[]): string =>
  classes.map((s) => prefix + s + suffix).join(' ')

export const landscape = (classes: string[]): string => decorateClasses('md:', '-ls', classes)

export const mobile = (classes: string[]): string => decorateClasses('', '', classes)

export const addMedia = (style: string, mediaSyle: string[]): string =>
  _ctl(style + ' ' + mobile(mediaSyle) + ' ' + landscape(mediaSyle))

/*
String litteral helper, in using tailwin intellisense add:
```
    "tailwindCSS.experimental.classRegex": [
        "tw`([^`]*)"
    ]
```
to the settings.json in VSCode.
*/
export const tw = (strings: TemplateStringsArray, ...patterns: (string | undefined)[]) =>
  _ctl(strings.map((s, i) => s + ' ' + patterns[i] || null).join(' '))
