import { default as errorM1H1Btk3RhMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/auth/error.vue?macro=true";
import { default as loginuQTa8kDjoUMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/auth/login.vue?macro=true";
import { default as dashboardpOoWKf3wMhMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/dashboard.vue?macro=true";
import { default as formsyZmLmnQERdMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/forms.vue?macro=true";
import { default as profileZ52TzCjS3iMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/profile.vue?macro=true";
import { default as responsiveOPvqDuZDsNMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/responsive.vue?macro=true";
import { default as styleL8qK2iLByfMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/style.vue?macro=true";
import { default as tables5bgYJsmP28Meta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/tables.vue?macro=true";
import { default as uipVubw7YeoCMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/ui.vue?macro=true";
import { default as indexmomIwalXYiMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/index.vue?macro=true";
import { default as _91task_93AFHAewgCviMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/[activity]/[task].vue?macro=true";
import { default as indexK61SDfDfC5Meta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/[activity]/index.vue?macro=true";
import { default as indexorH6yDtAljMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/index.vue?macro=true";
import { default as indexHdwSLkidztMeta } from "/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/index.vue?macro=true";
export default [
  {
    name: "auth-error",
    path: "/auth/error",
    meta: errorM1H1Btk3RhMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/auth/error.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginuQTa8kDjoUMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/auth/login.vue")
  },
  {
    name: "demo-dashboard",
    path: "/demo/dashboard",
    meta: dashboardpOoWKf3wMhMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/dashboard.vue")
  },
  {
    name: "demo-forms",
    path: "/demo/forms",
    meta: formsyZmLmnQERdMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/forms.vue")
  },
  {
    name: "demo-profile",
    path: "/demo/profile",
    meta: profileZ52TzCjS3iMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/profile.vue")
  },
  {
    name: "demo-responsive",
    path: "/demo/responsive",
    meta: responsiveOPvqDuZDsNMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/responsive.vue")
  },
  {
    name: "demo-style",
    path: "/demo/style",
    meta: styleL8qK2iLByfMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/style.vue")
  },
  {
    name: "demo-tables",
    path: "/demo/tables",
    meta: tables5bgYJsmP28Meta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/tables.vue")
  },
  {
    name: "demo-ui",
    path: "/demo/ui",
    meta: uipVubw7YeoCMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/demo/ui.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexmomIwalXYiMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/index.vue")
  },
  {
    name: "zone-zone-activity-task",
    path: "/zone/:zone()/:activity()/:task()",
    meta: _91task_93AFHAewgCviMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/[activity]/[task].vue")
  },
  {
    name: "zone-zone-activity",
    path: "/zone/:zone()/:activity()",
    meta: indexK61SDfDfC5Meta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/[activity]/index.vue")
  },
  {
    name: "zone-zone",
    path: "/zone/:zone()",
    meta: indexorH6yDtAljMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/[zone]/index.vue")
  },
  {
    name: "zone",
    path: "/zone",
    meta: indexHdwSLkidztMeta || {},
    component: () => import("/Users/bahulneel/Projects/ForMyEvent/for-my-chat/packages/ui/pages/zone/index.vue")
  }
]