<script setup>
import { gradientBgViaPrimary, gradientBgDark, gradientBgViaSubPrimary } from '../config/colors'
import { useStyleStore } from '../stores/style'

const props = defineProps({
  bg: {
    type: String,
    required: true,
    validator: (value) => ['purplePink', 'pinkRed'].includes(value),
  },
})

const colorClass = computed(() => {
  if (useStyleStore().darkMode) {
    return gradientBgDark
  }

  switch (props.bg) {
    case 'purplePink':
      return gradientBgViaPrimary
    case 'pinkRed':
      return gradientBgViaSubPrimary
  }

  return ''
})
</script>

<template>
  <div class="flex min-h-screen items-center justify-center" :class="colorClass">
    <slot card-class="w-11/12 md:w-7/12 lg:w-6/12 xl:w-4/12 shadow-2xl" />
  </div>
</template>
